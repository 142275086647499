import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { MEDIAS } from "../../ressources/MEDIAS";
import { CONSTANTS } from "../../ressources/CONSTANTS";
import { publicFetch } from "../../utils/apiHelper";
import { saveSession } from "../../utils/sessionHelper";
import ManageSignUp from "../../modules/managers/ManageSignUp";
import FormInput from "../../components/Form/FormInput";
import { validateEmail } from "../../utils/stringHelper";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [signUpModuleOpen, setSignUpModuleOpen] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    await publicFetch(
      "POST",
      "/user/token/",
      "Les informations de connexion sont erronées. Veuillez réessayer.",
      JSON.stringify({
        username: email,
        password: password,
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
        // TODO : Toaster
      } else {
        saveSession(response);
        navigate("/");
      }
    });
  };

  const handleSignUp = async (signUpForm) => {
    setLoading(true);
    await publicFetch(
      "POST",
      "/user/signup/",
      "Un problème est survenue, merci de ré-essayer.",
      JSON.stringify(signUpForm)
    ).then((response) => {
      if (response) {
        setLoading(false);
        setSignUpModuleOpen(false);
        window.PrimeToast.show({
          severity: "success",
          summary: `Votre demande a bien été prise en compte, nous revenons vers vous dans les plus brefs délais.`,
        });
        // Toaster
      }
    });
  };

  const emailIsValid = validateEmail(email);
  const passwordIsValid = password.length > 7;

  const formIsValid = emailIsValid && passwordIsValid;

  const forgottenPassword = (
    <span
      onClick={() => navigate("/forgotten-password")}
      className="cursor-pointer text-gray-400 hover:underline hover:text-custom-orange"
    >
      Mot de passe oublié ?
    </span>
  );

  return (
    <div className="h-screen w-screen bg-slate-700 text-slate-50 relative">
      <img
        src={MEDIAS.signinImage}
        className="absolute object-contain top-0 right-0 h-full"
      />

      <form className="h-full flex flex-col justify-between p-5">
        <div className="flex flex-row gap-5 items-center">
          <img
            src={MEDIAS.generalLogo}
            alt="Logo de Crescendo Imvest"
            className="h-12 w-12"
          />
          <span className=" text-2xl font-semibold">Crescendo Imvest</span>
        </div>

        <div className="flex flex-col gap-y-8 ml-36 w-[500px] mb-8">
          <span className="text-5xl font-semibold mb-8">Se connecter</span>
          <div className="flex flex-col gap-3">
            <FormInput
              label={"Adresse mail"}
              required
              isValid={emailIsValid}
              labelClassName={"text-slate-200"}
            >
              <InputText
                id="email"
                type="text"
                placeholder="Entrez votre adresse email"
                className="w-full mb-3"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormInput>
            <FormInput
              label={"Mot de passe"}
              required
              isValid={passwordIsValid}
              annotation={forgottenPassword}
              labelClassName={"text-slate-200"}
            >
              <InputText
                id="password"
                type="password"
                placeholder="Entrez votre mot de passe"
                className="w-full mb-3"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormInput>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <Button
              type="submit"
              label="Se connecter"
              className="grow"
              icon="pi pi-user"
              disabled={!formIsValid}
              loading={loading}
              onClick={handleLogin}
            />
            <Button
              label="Pas encore de compte ?"
              severity="secondary"
              loading={loading}
              onClick={() => setSignUpModuleOpen(true)}
            />
          </div>
        </div>

        <div className="flex flex-row gap-3 text-slate-300">
          <span>{`Version ${CONSTANTS.VERSION}`}</span>
          <span>-</span>
          <span>
            {`Conçu, développé et maintenu par le `}{" "}
            <a
              href="https://collectifnode.fr"
              target="_blank"
              rel="noreferrer"
              className="text-yellow-500 hover:underline ml-1"
            >
              Collectif Node
            </a>
          </span>
        </div>
      </form>

      <ManageSignUp
        loading={loading}
        isOpen={signUpModuleOpen}
        handleClose={() => {
          setSignUpModuleOpen(false);
        }}
        handleSignUp={handleSignUp}
      />
    </div>
  );
}

export default SignIn;
